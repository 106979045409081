// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bT_kz";
export var caseStudyBackground__lineColor = "bT_kq";
export var caseStudyHead__imageWrapper = "bT_kn";
export var caseStudyProjectsSection = "bT_kB";
export var caseStudyQuote__bgQuote = "bT_mJ";
export var caseStudyQuote__bgRing = "bT_kw";
export var caseStudyVideo__ring = "bT_kF";
export var caseStudy__bgDark = "bT_km";
export var caseStudy__bgLight = "bT_kl";
export var caseStudy__bgLightGray = "bT_mH";